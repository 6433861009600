@import '@var';

.page-wrap {
  @include container($max-width);
}
.section-wrap {
  @include container($inner-width);
}
.profile-wrap {
  @include container($profile-width);
}