@mixin bone () {
  display: block;
  background: linear-gradient(135deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: skeleton-ani 1.4s ease .1s infinite;
  border-radius: 4px;
}

.skeleton-wrap {
  span {
    @include bone()
  }
}
.bone {
  @include bone()
}

@keyframes skeleton-ani {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}