@font-face {
  font-display: swap;
  font-family: "icon"; /* Project id 3154141 */
  src: url('iconfont.eot?t=1692167086195'); /* IE9 */
  src: url('iconfont.eot?t=1692167086195#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('iconfont.woff2?t=1692167086195') format('woff2'),
       url('iconfont.woff?t=1692167086195') format('woff'),
       url('iconfont.ttf?t=1692167086195') format('truetype');
}

.icon {
  font-family: "icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-heart-fill:before {
  content: "\e642";
}

.icon-heart:before {
  content: "\e641";
}

.icon-search:before {
  content: "\e615";
}

.icon-communications:before {
  content: "\e613";
}

.icon-tracked-places:before {
  content: "\e614";
}

.icon-password:before {
  content: "\e610";
}

.icon-calendar:before {
  content: "\e627";
}

.icon-email-fill:before {
  content: "\e6ee";
}

.icon-doller:before {
  content: "\e8fc";
}

.icon-edit:before {
  content: "\e6eb";
}

.icon-lock:before {
  content: "\e62d";
}

.icon-arrow-right-fill:before {
  content: "\e68a";
}

.icon-up:before {
  content: "\e6a9";
}

.icon-correct:before {
  content: "\e662";
}

.icon-info:before {
  content: "\e664";
}

.icon-ok:before {
  content: "\e65a";
}

.icon-phone:before {
  content: "\e6c2";
}

.icon-back:before {
  content: "\e612";
}

.icon-minus:before {
  content: "\ecd0";
}

.icon-plus:before {
  content: "\ecd3";
}

.icon-link:before {
  content: "\e611";
}

.icon-share:before {
  content: "\e60f";
}

.icon-news-cover:before {
  content: "\e689";
}

.icon-avatar:before {
  content: "\e60c";
}

.icon-offices:before {
  content: "\e60d";
}

.icon-house-cover:before {
  content: "\e60e";
}

.icon-bath:before {
  content: "\e609";
}

.icon-bed:before {
  content: "\e60a";
}

.icon-car:before {
  content: "\e60b";
}

.icon-address:before {
  content: "\e7e7";
}

.icon-close:before {
  content: "\e6e9";
}

.icon-plus-square:before {
  content: "\e797";
}

.icon-history:before {
  content: "\e604";
}

.icon-email:before {
  content: "\e605";
}

.icon-chat:before {
  content: "\e606";
}

.icon-logout:before {
  content: "\e607";
}

.icon-saved-search:before {
  content: "\e608";
}

.icon-notification:before {
  content: "\e601";
}

.icon-profile:before {
  content: "\e602";
}

.icon-facebook:before {
  content: "\ec01";
}

.icon-instagram:before {
  content: "\ec09";
}

.icon-linkedin:before {
  content: "\ec0e";
}

.icon-arrow-left:before {
  content: "\e685";
}

.icon-arrow-up:before {
  content: "\e686";
}

.icon-arrow-down:before {
  content: "\e687";
}

.icon-arrow-right:before {
  content: "\e688";
}

