
img[imgbg=loading],
.image-loading {
  background-color: var(--color-theme-light);
  background-image: url('../../../public/icon/const/img-loading.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40%;
  background-size: min(50%, 100px);
}

img[imgsize=sm] {
  background-size: 20%;
}