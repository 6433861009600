$responsive-breakpoint-sm: 640px;
$responsive-breakpoint-md: 768px;
$responsive-breakpoint-lg: 1024px;
$responsive-breakpoint-xl: 1280px;
$responsive-breakpoint-xxl: 1536px;

$max-width: 1520px;
$inner-width: 1120px;
$profile-width: 1000px;

// container
@mixin container($width, $padding: var(--container-pd), $middle: true) {
  @include transition(padding);
  max-width: 100%;
  width: calc($width + var(--container-pd) * 2);
  @if ($padding) {
    padding-left: $padding;
    padding-right: $padding;
    box-sizing: border-box;
  }
  @if ($middle) {
    margin-left: auto;
    margin-right: auto;
  }
}

// transition
@mixin transition ($attr, $duration: .25s, $timing: ease-out, $dealy: 0s) {
  transition: $attr $duration $timing $dealy;
}